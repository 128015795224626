import {defineStore} from 'pinia'

export const usePreferencesDataStore = defineStore({
  id: 'country',
  state: () => ({
    countries: [],
    citiesOfSelectedCountry: [],
    preferredLocations: [],
  }),
  actions: {
    async getAllCountries() {
      try {
        const apiUrl = 'country'

        const response = await $fetch(useRuntimeConfig().public.apiBaseUrl + '/' + apiUrl, {method: 'GET'})
        const countries = response?.data?.countries
        this.countries = countries
        return countries
      } catch (error) {
        console.error('Error', error)
      }
    },

    async getCitiesOfCountry(countryCode) {
      try {
        const apiUrl = `country/${countryCode}/city`

        const response = await $fetch(useRuntimeConfig().public.apiBaseUrl + '/' + apiUrl, {method: 'GET'})

        const cities = response?.data?.cities
        this.citiesOfSelectedCountry = cities
        return cities
      } catch (error) {
        console.error('Error', error)
      }
    },

    async getPreferredLocations() {
      try {
        const apiUrl = 'settings'
        const settings = await useSettingsStore().getSettings()
        const settingsPreferredLocations = settings.preferred_locations
        return settingsPreferredLocations
      } catch (error) {
        console.error('Error', error)
      }
    },
  },
})
